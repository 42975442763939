import React from 'react';
import styled from 'styled-components';
import LoginImage from "./assets/svg/LoginImage.svg";
import "./App.scss"

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  background: #fff5fa;
`;

const LeftColumn = styled.div`
  flex: 1;
  display: flex;
`;

const LeftImage = styled.img`
  width: 100%;
  max-width: 400px;
  height: 100vh;
  object-fit: cover;
`;

const RightColumn = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormWrapper = styled.div`
  padding: 70px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px 0px rgba(106, 17, 60, 0.25);
`;

const Heading = styled.span`
  color: #AA1A5F;
  font-family: Poppins;
  font-size: 33px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  letter-spacing: 0.36px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Paragraph = styled.p`
  width: 416px;
`;

const App = () => {

  const handleSignIn = () => {
    window.open('http://staging.tpmready.com', '_blank');};
  return (
    <Wrapper>
      <LeftColumn>
        <LeftImage src={LoginImage} alt="Login Image" />
      </LeftColumn>
      <RightColumn>
        <FormWrapper>
          <TextWrapper>
            <Heading><span><b>TPM</b></span>ready</Heading>
            <Heading>Management System</Heading>
            <Paragraph>
              TPMready is a product of International Advisory, Products and Systems Ltd. (i-APS)
            </Paragraph>
          </TextWrapper>
          <button style={{cursor: 'pointer'}} onClick={handleSignIn} className='btnSignIn'>Sign in</button>
        </FormWrapper>
      </RightColumn>
    </Wrapper>
  );
};

export default App;