import React from "react";
import "./loginStyle.scss";
import { Button } from "antd";
import image from "../src/assets/svg/LoginImage.svg";
import 'bootstrap/dist/css/bootstrap.min.css';

const Login = () => {
    const redirectToLogin = () => {
        window.open("https://staging.tpmready.com/login", "_blank");
      };
  return (
    <div className="wrapper">
      <div className="row no-gutters">
        <div className="col-md-6 left-column">
          <img src={image} alt="Login Visual" className="leftImage" />
        </div>
        <div className="col-md-6 right-column">
          <div className="form-wrapper">
            <h2>TPMready Management System</h2>
            <p>TPMready is a product of International Advisory, Products and Systems Ltd. (i-APS)</p>
            <Button type="primary" onClick={redirectToLogin}>
              Sign in
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
